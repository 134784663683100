<template>
  <div class="box box--blog">
    <div class="box__inner box__inner--blog">
      <div class="blog-search">
        <BlogSubcategories :current="subcategory.id" />
        <button class="blog-search__button" type="button" @click="$router.push('/theflyreport/search')">
          <div v-if="$store.state.view.theme === 'dark'">
            <SvgIcon name="magnifier-light"/>
          </div>
          <div v-if="$store.state.view.theme === 'light'">
           <SvgIcon name="magnifier-dark"/>
          </div>
        </button>
      </div>
      <div class="blog-interface">
        <BlogSubcategoryList :title="subcategoryTitle" :subcategoryId="subcategory.id"/>
        <BlogFeed/>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import BlogSubcategoryList from '@/components/Blog/BlogSubcategoryList/BlogSubcategoryList';
  import BlogFeed from '@/components/Blog/BlogFeed/BlogFeed';
  import BlogSubcategories from '@/components/Blog/BlogSubcategories/BlogSubcategories';
  import './Blog.styl';

  export default {
    name: 'BlogSubcategory',
    components: {
      SvgIcon,
      BlogSubcategoryList,
      BlogFeed,
      BlogSubcategories,
    },
    computed: {
      subcategory () {
        return this.$store.state.blog.subcategories.find(({id}) => id.toString() === this.$route.params.id) || {}
      },
      subcategoryTitle () {
        return _.get(this.subcategory, 'attributes.name')
      },
    },
    watch: {
      '$route' (to, from) {
         this.$store.dispatch('getSubcategoryList', { subcategory: this.$route.params.id });
      },
    },
    mounted () {
      this.$store.dispatch('getSubcategoryList', { subcategory: this.$route.params.id });
      this.$store.dispatch('getFeed');
    },
    metaInfo: {
      title: 'The FLY Report',
    },
  };
</script>
