<template>
  <div class="blog-subcategory-list">
    <div class="blog-subcategory-list__wrapper">
      <div>
        <div class="blog-subcategory-list__header">{{ title }}</div>
      </div>
      <vue-scroll :ops="ops">
        <div class="blog-subcategory-list__item">
          <BlogItem v-for="elem of blogSubcategoryList" :key="elem.id" :elem="elem" :showPublishedAt="showPublishedAt"/>
        </div>
        <div class="blog-subcategory-list__load-more" @click="loadMore" v-if="isLoadMoreVisible">Load more</div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import BlogItem from '@/components/Blog/BlogItem/BlogItem';
  import './BlogSubcategoryList.styl';

  export default {
    name: 'BlogSubcategoryList',
    props: ['title', 'subcategoryId'],
    data: () => ({
      ops: {
        rail: {
          size: '4px',
        },
        bar: {
          size: '4px',
          background: 'var(--scroll-bar-background)',
        },
      },
      showPublishedAt: true,
    }),
    computed: {
      blogSubcategoryList () {
        const blogSubcategoryList = this.$store.state.blog.blogSubcategoryList;
        return blogSubcategoryList.map(article => {
              let categories = article.categories.filter(c => c.name !== 'Top').map(c => c.name).join(' • ');
              const subcategories = article.subcategories.filter(s => s.name !== 'Today' || moment(article.publishedAt).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')).map(s => s.name).join(' • ');
              if (categories && subcategories) {
                categories += ' • ';
              }
              return {...article, categoriesSubcategories: (categories + subcategories)}
            });
      },
      isLoadMoreVisible () {
        const pagination = this.$store.state.blog.blogSubcategoryListPagination;
        return pagination && pagination.page < pagination.pageCount;
      },
    },
    methods: {
      loadMore () {
        const pagination = this.$store.state.blog.blogSubcategoryListPagination;
        const data = { subcategory: this.subcategoryId, pagination: { ...pagination, page: pagination.page + 1 } };
        this.$store.dispatch('loadMoreSubcategory', data);
      },
    },
    components: {
      BlogItem,
    },
  };
</script>
